import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import logoCircle from '../../../logo-circle.png';
import { getGameNumber } from '../../../utils';

import styles from './index.module.css';

interface HeaderProps {
  date?: string;
  gameNumber?: number;
}

const HeaderComponent: FC<HeaderProps> = ({ date, gameNumber }) => {
  return (
    <header className={styles.header} id="header">
      <div className={styles.headerInner}>
        <Link to="/" className={styles.titleLink}>
          <img
            src={logoCircle}
            alt="Analanche | The Game"
            title="Analanche | The Game"
            className={styles.logo}
          />
          Analanche #{gameNumber || getGameNumber(date)}
          <sup>TM</sup>
        </Link>
        <h4>Decode the anagram as the blocks fall</h4>
      </div>
    </header>
  );
};

export const Header = React.memo(HeaderComponent);
