import React, { FC, memo, SyntheticEvent, useContext } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';

import { DIFFICULTY_LEVEL } from '../../../../constants';
import { DifficultyContext } from '../../../../context/difficultyContext';
import { HowItWorks } from '../../../../components/howItWorks';
import { Feedback } from '../../../../components/feedback';

import { useTopControls } from './useTopControls';

import styles from './index.module.css';

interface TopControlsProps {
  visualTimer: number;
  isCorrect: boolean;
  isRevealed: boolean;
  togglePlayPause: (e: SyntheticEvent) => void;
  isInSwapMode: boolean;
  handleRevealClick: (e: SyntheticEvent) => void;
  handlePeekActiveCharacter: () => void;
  warmupTimer: number;
}

export const TopControlsComponent: FC<TopControlsProps> = ({
  visualTimer,
  isCorrect,
  isRevealed,
  togglePlayPause,
  isInSwapMode,
  handleRevealClick,
  handlePeekActiveCharacter,
  warmupTimer,
}) => {
  const difficultyContext = useContext(DifficultyContext);
  const {
    isManualShown,
    showManual,
    hideManual,
    isFeedbackShown,
    showFeedback,
    hideFeedback,
    decreaseLevel,
    levelStyles,
  } = useTopControls({
    togglePlayPause,
    isInSwapMode,
  });

  return (
    <aside className={styles.topControls} id="topControls">
      <div className={styles.inner}>
        <div className={styles.left}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={styles.howItWorks}
            title="How to Play"
            onClick={showManual}
          />
          {isManualShown && <HowItWorks hideManual={hideManual} />}
          <a href="#" className={styles.contactUs} onClick={showFeedback}>
            <FontAwesomeIcon
              icon={faCommentDots}
              title="Leave Feedback"
              className={styles.contactUsIcon}
            />
          </a>
          {isFeedbackShown && <Feedback hidePopup={hideFeedback} />}
          {difficultyContext?.level === DIFFICULTY_LEVEL.ADVANCED && (
            <div
              className={classNames(styles.level, levelStyles)}
              title={`Your difficulty level is ${difficultyContext?.level}`}
              onClick={decreaseLevel}
            />
          )}
          {!!visualTimer && (
            <div className={classNames(styles.timer, 'button')}>
              {Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(visualTimer / 1000)}
            </div>
          )}
        </div>
        <div className={styles.right}>
          {!(isCorrect || isRevealed || warmupTimer) && (
            <>
              {difficultyContext?.level !== DIFFICULTY_LEVEL.ADVANCED && !isInSwapMode && (
                <button
                  className={classNames('button', styles.peek)}
                  onClick={handlePeekActiveCharacter}
                  title="Peek active character"
                  disabled={!!warmupTimer}
                >
                  Peek
                </button>
              )}
              <button
                className={classNames('button', styles.reveal)}
                onClick={handleRevealClick}
                title="Finish and Reveal the Answer"
                disabled={!!warmupTimer}
              >
                Reveal
              </button>
            </>
          )}
        </div>
      </div>
    </aside>
  );
};

export const TopControls = memo(TopControlsComponent);
