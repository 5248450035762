import React, { useCallback, useContext } from 'react';

import { DifficultyContext } from '../../../../context/difficultyContext';
import { DIFFICULTY_LEVEL } from '../../../../constants';

export function useWarmupCountdown({
  setWarmupTimer,
}: {
  setWarmupTimer: React.Dispatch<React.SetStateAction<number>>;
}) {
  const difficultyContext = useContext(DifficultyContext);

  const handleLevelIncrease = useCallback(() => {
    if (difficultyContext?.level && difficultyContext.level === DIFFICULTY_LEVEL.INTERMEDIATE) {
      if (window.confirm('Congratulations, you have unlocked the Black slopes. Good luck!')) {
        difficultyContext?.setLevel(difficultyContext.level + 1);
      }
    }
  }, [difficultyContext]);

  const handleSkip = useCallback(() => {
    setWarmupTimer(0);
  }, []);

  return {
    handleLevelIncrease,
    handleSkip,
  };
}
