export enum DIFFICULTY_LEVEL {
  BEGINNER = 1,
  INTERMEDIATE = 2,
  ADVANCED = 3,
}

export enum DAY_OF_WEEK {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const SERVICE_MAIL = 'analanche.game@gmail.com';

export const CORRECT_SQUARE = '✅';
export const WRONG_SQUARE = '❌';
