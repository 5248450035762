import React, { FC } from 'react';
import classNames from 'classnames';

import { Character } from '../../../../types';

import styles from './index.module.css';

export interface CellProps {
  character?: Character;
  sideSize: number;
  clickHandler: () => void;
  cellClasses: string;
}

export const Cell: FC<CellProps> = ({ character, sideSize, clickHandler, cellClasses }) => {
  return (
    <div
      className={classNames(styles.cell, cellClasses)}
      onClick={clickHandler}
      style={{
        width: sideSize,
        height: sideSize,
        fontSize: sideSize * 0.85,
      }}
    >
      {character?.character}
    </div>
  );
};
