import { Character } from './types';

export function findLastAvailableIndex(charactersList: Character[]) {
  return charactersList.reduce((maxIndex, currentCharacter, currentIndex, arr) => {
    if (
      !currentCharacter.isPlaced &&
      !currentCharacter.isActive &&
      (maxIndex === -1 || currentCharacter.currentPosition.x > arr[maxIndex].currentPosition.x)
    ) {
      return currentIndex;
    }

    return maxIndex;
  }, -1);
}

export function findFirstAvailableIndex(charactersList: Character[]) {
  return charactersList.reduce((minIndex, currentCharacter, currentIndex, arr) => {
    if (
      !currentCharacter.isPlaced &&
      !currentCharacter.isActive &&
      (minIndex === -1 || currentCharacter.currentPosition.x < arr[minIndex].currentPosition.x)
    ) {
      return currentIndex;
    }
    return minIndex;
  }, -1);
}

export function findNextClosestAvailableIndex(charactersList: Character[]) {
  const activeCharacter = charactersList.find((character) => character.isActive);
  if (!activeCharacter) {
    return -1;
  }
  return charactersList.reduce((nextClosestIndex, currentCharacter, currentIndex, array) => {
    if (currentCharacter.isActive || currentCharacter.isPlaced) {
      return nextClosestIndex;
    }
    const isNextToCurrent = currentCharacter.currentPosition.x > activeCharacter.currentPosition.x;

    if (!isNextToCurrent) {
      return nextClosestIndex;
    }

    if (nextClosestIndex === -1) {
      return currentIndex;
    }

    const isCloser = currentCharacter.currentPosition.x < array[nextClosestIndex].currentPosition.x;
    if (isCloser) {
      return currentIndex;
    }
    return nextClosestIndex;
  }, -1);
}

export function findPrevClosestAvailableIndex(charactersList: Character[]) {
  const activeCharacter = charactersList.find((character) => character.isActive);
  if (!activeCharacter) {
    return -1;
  }
  return charactersList.reduce((prevClosestIndex, currentCharacter, currentIndex, array) => {
    if (currentCharacter.isActive || currentCharacter.isPlaced) {
      return prevClosestIndex;
    }
    const isPrevToCurrent = currentCharacter.currentPosition.x < activeCharacter.currentPosition.x;

    if (!isPrevToCurrent) {
      return prevClosestIndex;
    }

    if (prevClosestIndex === -1) {
      return currentIndex;
    }

    const isCloser = currentCharacter.currentPosition.x > array[prevClosestIndex].currentPosition.x;
    if (isCloser) {
      return currentIndex;
    }
    return prevClosestIndex;
  }, -1);
}
