import React, { FC, SyntheticEvent } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { useFeedback } from './useFeedback';

import styles from './index.module.css';

const mountingPoint = document.body;

interface FeedbackProps {
  hidePopup: (e: SyntheticEvent) => void;
}

export const Feedback: FC<FeedbackProps> = ({ hidePopup }) => {
  const { isSent, email, handleEmailChange, body, handleBodyChange, handleFormSubmit } =
    useFeedback();

  return createPortal(
    <div className={styles.pageOverlay}>
      {!isSent ? (
        <form
          className={styles.contactUsForm}
          name="contactUsForm"
          method="POST"
          encType="multipart/form-data"
          onSubmit={(e: SyntheticEvent) => {
            void handleFormSubmit(e);
          }}
        >
          <h2 className={styles.title}>Got feedback? You're in the right place</h2>
          <a href="#" onClick={hidePopup} className={styles.close}>
            X
          </a>
          <input
            type="email"
            name="email"
            placeholder="your.email@example.com"
            required={true}
            value={email}
            onChange={handleEmailChange}
          />
          <textarea
            name="feedback"
            placeholder="How can we improve Analanche?"
            rows={10}
            cols={40}
            required={true}
            value={body}
            onChange={handleBodyChange}
          />
          <div className={styles.actions}>
            <button type="submit" className={classNames('button', styles.button)}>
              Send
            </button>
          </div>
        </form>
      ) : (
        <div className={classNames(styles.contactUsForm, styles.thanks)}>
          <h1 className={styles.title}>Thank you for your feedback!</h1>
          <a href="#" onClick={hidePopup} className={styles.close}>
            X
          </a>
        </div>
      )}
    </div>,
    mountingPoint,
  );
};
