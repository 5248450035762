import React, { FC, SyntheticEvent } from 'react';
import { createPortal } from 'react-dom';

import styles from './howItWorks.module.css';

const mountingPoint = document.body;

interface HowItWorksProps {
  hideManual: (e: SyntheticEvent) => void;
}

export const HowItWorks: FC<HowItWorksProps> = ({ hideManual }) => {
  return createPortal(
    <div className={styles.pageOverlay}>
      <div className={styles.howItWorks}>
        <h1 className={styles.title}>How to play Analanche:</h1>
        <a href="#" onClick={hideManual} className={styles.close}>
          X
        </a>
        <div className={styles.body}>
          <ul>
            <li>
              Initial letters are a scrambled word or phrase. You get a few seconds to stare at them
              before letters begin to fall and timer starts.
            </li>
            <li>
              As a letter falls, use the arrow keys to move it to where you think the right place
              could be on the bottom row. Letters in right places turn green.
            </li>
            <li>
              If stuck, clicking on "Peek" will automatically take currently falling letter to its
              correct place ... but with a time penalty.
            </li>
            <li>
              Once bottom row is filled, you will be able to swap letters if they aren't already
              correct. To swap two letters, tap on the first one, and when it flashes, tap on a
              second one. If you tap first letter in error, tap on it again to cancel.
            </li>
            <li>
              Each time you swap, you need to get at least one letter correct in order to earn
              another swap. Else, game is over.
            </li>
            <li>
              And oh, if you would like a more challenging version of the game, there's a way to get
              to "Black Level" during initial countdown. We will let you discover how.
            </li>
            <li>
              If you find Black level too tough, click on the black circle to get back to the
              regular slopes.
            </li>
          </ul>
        </div>
        <footer className={styles.footer}>
          <i>Enjoy the Analanche slopes!</i>
        </footer>
      </div>
    </div>,
    mountingPoint,
  );
};
