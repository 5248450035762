import { useContext, useState } from 'react';

import { Character, PhraseData } from '../../../../types';
import { DIFFICULTY_LEVEL } from '../../../../constants';
import { DifficultyContext } from '../../../../context/difficultyContext';
import { getFormattedSharableAnswer, getGameNumber } from '../../../../utils';

export interface ShareProps {
  response: PhraseData;
  charactersList: Character[];
  visualTimer: number;
  isCorrect: boolean;
}

/**
 * Got characters from here: https://copychar.cc/emoji/
 * For further investigations if needed - https://stackoverflow.com/questions/74838274/copy-html-rich-text-using-navigator-clipboard
 * @param visualTimer
 * @param isCorrect
 * @param clue
 */
export function useShare({ response, charactersList, visualTimer, isCorrect }: ShareProps) {
  const difficultyContext = useContext(DifficultyContext);

  const [isCopied, setIsCopied] = useState<boolean>();
  const formattedTime = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(
    visualTimer / 1000,
  );

  const gameNumber = response.number || getGameNumber();

  const handleShare = () => {
    // eslint-disable-next-line max-len
    let message = `Beat this: I solved Analanche #${gameNumber} in ${formattedTime} seconds!\n\r`;
    if (difficultyContext?.level === DIFFICULTY_LEVEL.ADVANCED) {
      // eslint-disable-next-line max-len
      message = `Beat this: I solved Analanche #${gameNumber} Black Level in ${formattedTime} seconds!\n\r`;
    }

    if (!isCorrect) {
      message = `I tried today's Analanche #${gameNumber}!\n\r`;
      if (difficultyContext?.level === DIFFICULTY_LEVEL.ADVANCED) {
        message = `I tried today's Analanche #${gameNumber} Black Level!\n\r`;
      }
    }

    message += getFormattedSharableAnswer(response, charactersList);

    message += '\nhttps://analanche.com/play';

    const data2Share = {
      title: 'Analanche | The Game',
      text: message,
    };

    if (navigator.canShare && navigator.canShare(data2Share)) {
      navigator
        .share(data2Share)
        .then(() => setIsCopied(true))
        .catch(() => setIsCopied(false));
    } else if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(message)
        .then(() => setIsCopied(true))
        .catch(() => setIsCopied(false));
    } else {
      console.error('Unable to use `Share` nor `Clipboard` APIs');
    }
  };

  return { isCopied, handleShare };
}
