import React, { FC } from 'react';

import styles from './error.module.css';

interface ErrorProps {
  phraseError: string;
}

export const Error: FC<ErrorProps> = ({ phraseError }) => {
  return <div className={styles.error} dangerouslySetInnerHTML={{ __html: phraseError }} />;
};
