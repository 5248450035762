import { useCallback, useEffect, useState } from 'react';
import { useMobileOrientation, isMobile } from 'react-device-detect';
import ReactGA from 'react-ga4';

import {
  countPlacedCharacters,
  getFormattedSharableAnswer,
  getLocalDateWOTime,
  moveActiveCharacterDown,
  setNextActiveCharacter,
} from '../../../../utils';

import { useInitializeGame } from './useInitializeGame';
import { useDefineCharactersList } from './useDefineCharactersList';
import { useTimers } from './useTimers';
import { useControls } from './useControls';

export function useGame() {
  const orientation = useMobileOrientation();
  const [shouldResumeInPortrait, setShouldResumeInPortrait] = useState(true);
  const [shouldResumeAfterBecomeVisible, setShouldResumeAfterBecomeVisible] = useState(true);

  const {
    isLoading,
    phraseData,
    phraseError,
    phraseNoSpaces,
    scrambledNoSpaces,
    isInSwapMode,
    setIsInSwapMode,
    isRevealed,
    setIsRevealed,
    isCorrect,
    setIsCorrect,
    shouldTransitGridToLine,
    strikesLeft,
    setStrikesLeft,
    sideSize,
    mainElementRef,
    recalculateCellSize,
    params,
  } = useInitializeGame();

  const { charactersList, setCharactersList } = useDefineCharactersList({
    phraseData,
    phraseNoSpaces,
    scrambledNoSpaces,
  });

  const {
    timer,
    isPaused,
    setIsPaused,
    pausedTimer,
    warmupTimer,
    setWarmupTimer,
    visualTimer,
    setVisualTimer,
  } = useTimers({
    phraseData,
    isCorrect,
    isRevealed,
    strikesLeft,
  });

  const {
    initialSwapCharacter,
    handleSolve,
    handleKeyControlsMouseClicks,
    handleRevealClick,
    handleSwapSelection,
    handleDropAndSwap,
    togglePlayPause,
    handlePeekActiveCharacter,
    isActiveCharacterFlashingTimeout,
  } = useControls({
    charactersList,
    setCharactersList,
    isPaused,
    setIsPaused,
    setIsRevealed,
    setIsInSwapMode,
    phraseNoSpaces,
    setStrikesLeft,
    setVisualTimer,
    warmupTimer,
  });

  const togglePlayPauseOnVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      if (isPaused) {
        setShouldResumeAfterBecomeVisible(false);
      }
      setIsPaused(true);
    } else {
      if (shouldResumeAfterBecomeVisible) {
        setIsPaused(false);
      }
    }
  }, [isPaused, setIsPaused, shouldResumeAfterBecomeVisible, setShouldResumeAfterBecomeVisible]);

  /**
   * Contains main game logic to drop characters based on time when in Grid-Mode
   * and transitions to swap mode based on conditions
   */
  useEffect(() => {
    if (!isPaused && !isInSwapMode) {
      const activeCharacterIndex = charactersList?.findIndex((character) => character.isActive);

      if (
        charactersList &&
        typeof activeCharacterIndex !== 'undefined' &&
        activeCharacterIndex !== -1
      ) {
        // If active character IS the last one - force `isSolved` state and exit
        if (
          phraseData &&
          countPlacedCharacters(charactersList) + 1 === phraseData.character_count
        ) {
          handleSolve();
          return;
        }

        // If active character is NOT at the bottom - move one block down and exit
        if (
          charactersList[activeCharacterIndex].currentPosition.y <
          charactersList[activeCharacterIndex].correctPosition.y
        ) {
          const characterListWithNewPosition = moveActiveCharacterDown(charactersList);
          setCharactersList(characterListWithNewPosition);

          if (
            characterListWithNewPosition[activeCharacterIndex].currentPosition.y <
            characterListWithNewPosition[activeCharacterIndex].correctPosition.y
          ) {
            return;
          }

          setCharactersList(setNextActiveCharacter(characterListWithNewPosition, false));
          return;
        }

        setCharactersList(setNextActiveCharacter(charactersList, false));
        if (!charactersList.some((character) => !character.isPlaced)) {
          setIsInSwapMode(true);
        }
      }
    }
  }, [timer, isInSwapMode, isPaused]);

  /**
   * Observes `charactersList` state and decides whether game is solved correctly or not
   */
  useEffect(() => {
    if (charactersList && !isRevealed) {
      const isCorrect = charactersList.every((character) => {
        return (
          character.correctPosition.x.includes(character.currentPosition.x) &&
          character.correctPosition.y === character.currentPosition.y
        );
      });
      if (isCorrect) {
        setIsCorrect(isCorrect);
        setIsInSwapMode(true);
      }
    }
  }, [charactersList, isRevealed, setIsCorrect]);

  /**
   * Subscribes to orientation change and locks screen from interactions
   */
  useEffect(() => {
    // Pausing the game and storing the paused state
    if (isMobile && orientation.isLandscape) {
      if (!isPaused) {
        setShouldResumeInPortrait(true);
      }
      setIsPaused(true);
    }

    // Resuming the game if it wasn't paused before switching to landscape mode
    if (isMobile && orientation.isPortrait && shouldResumeInPortrait) {
      setIsPaused(false);
      setShouldResumeInPortrait(false);
    }
  }, [
    isPaused,
    setIsPaused,
    shouldResumeInPortrait,
    setShouldResumeInPortrait,
    isMobile,
    orientation.isLandscape,
    orientation.isPortrait,
  ]);

  /**
   * Subscribes to `visibilitychange` event and pauses the game till user returns
   */
  useEffect(() => {
    window.document.addEventListener('visibilitychange', togglePlayPauseOnVisibilityChange);
    return () =>
      window.document.removeEventListener('visibilitychange', togglePlayPauseOnVisibilityChange);
  }, [togglePlayPauseOnVisibilityChange]);

  /**
   * Subscribes to multiple flags to see if the game is over - no matter success or failure
   */
  useEffect(() => {
    if (isCorrect || isRevealed || !strikesLeft) {
      const gameDate = params.date || getLocalDateWOTime();
      localStorage.setItem(
        gameDate,
        JSON.stringify({
          answer: getFormattedSharableAnswer(phraseData!, charactersList!),
          clue: phraseData?.clue,
        }),
      );
    }
  }, [isCorrect, strikesLeft, isRevealed, params?.date]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview' });
  }, []);

  return {
    isLoading,
    response: phraseData,
    phraseError,
    charactersList,
    timer,
    pausedTimer,
    isPaused,
    togglePlayPause,
    isInSwapMode,
    handleSolve,
    handleSwapSelection,
    handleDropAndSwap,
    handleKeyControlsMouseClicks,
    initialSwapCharacter,
    isCorrect,
    isRevealed,
    handleRevealClick,
    handlePeekActiveCharacter,
    shouldTransitGridToLine,
    strikesLeft,
    sideSize,
    mainElementRef,
    warmupTimer,
    setWarmupTimer,
    recalculateCellSize,
    visualTimer,
    params,
    isActiveCharacterFlashingTimeout,
  };
}
