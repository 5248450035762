import React, { FC, useContext } from 'react';
import classNames from 'classnames';

import { DifficultyContext } from '../../../../context/difficultyContext';
import { Character, PhraseData } from '../../../../types';
import { DIFFICULTY_LEVEL } from '../../../../constants';

import { Cell } from './cell';

import styles from './index.module.css';

interface GridCellProps {
  character?: Character;
  response: PhraseData;
  sideSize: number;
  cellPosition: {
    x: number;
    y: number;
  };
  handleDropAndSwap: ({ x, y }: { x: number; y: number }) => void;
  isActiveCharacterFlashingTimeout?: number;
  isMatchingCharacterTakesWrongPlace?: boolean;
}

export const GridCell: FC<GridCellProps> = ({
  character,
  response,
  sideSize,
  cellPosition,
  handleDropAndSwap,
  isActiveCharacterFlashingTimeout,
  isMatchingCharacterTakesWrongPlace,
}) => {
  const difficultyContext = useContext(DifficultyContext);

  const cellClasses = classNames({
    [styles.withCharacter]: !!character,
    [styles.isInCorrectPosition]:
      difficultyContext?.level === DIFFICULTY_LEVEL.INTERMEDIATE &&
      character?.currentPosition.y === character?.correctPosition.y &&
      character?.correctPosition.x.includes(character?.currentPosition.x),
    [styles.isFlashing]:
      character &&
      isActiveCharacterFlashingTimeout &&
      (character.isActive || isMatchingCharacterTakesWrongPlace),
  });

  const clickHandler = () => {
    if (character) {
      return;
    }
    if (cellPosition.y + 1 < response.rows) {
      return;
    }
    handleDropAndSwap({ x: cellPosition.x, y: cellPosition.y });
  };

  return (
    <Cell
      character={character}
      sideSize={sideSize}
      clickHandler={clickHandler}
      cellClasses={cellClasses}
    />
  );
};
