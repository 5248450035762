import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import logo from '../logo.png';

import styles from './splashScreen.module.css';

interface SplashScreenProps {
  children: React.ReactNode;
}

export const SplashScreen: FC<SplashScreenProps> = ({ children }) => {
  return (
    <div className={styles.splashScreen}>
      <Link to="/">
        <img src={logo} title="Analanche Logo" alt="Analanche Logo" className={styles.logo} />
      </Link>
      <h1 className={styles.title}>
        <Link to="/" className={styles.titleLink}>
          Analanche<sup>TM</sup>
        </Link>
      </h1>
      {children}
    </div>
  );
};
