import React, { FC, SyntheticEvent, useCallback, useContext } from 'react';
import classNames from 'classnames';

import { DIFFICULTY_LEVEL } from '../../../constants';
import { DifficultyContext } from '../../../context/difficultyContext';
import { SplashScreen } from '../../../components/splashScreen';

import styles from './index.module.css';

export const Presets: FC = () => {
  const context = useContext(DifficultyContext);

  const handleLevelSelection = useCallback((level: DIFFICULTY_LEVEL) => {
    return (e: SyntheticEvent) => {
      e.preventDefault();
      context?.setLevel(level);
    };
  }, []);

  return (
    <SplashScreen>
      <div className={styles.difficulty}>
        <h3>Choose Slope Level</h3>
        <ul className={styles.levelsList}>
          <li>
            <button
              className={classNames(styles.levelCircle, styles.blue)}
              onClick={handleLevelSelection(DIFFICULTY_LEVEL.BEGINNER)}
            >
              Beginner
            </button>
          </li>
          <li>
            <button
              className={classNames(styles.levelCircle, styles.red)}
              onClick={handleLevelSelection(DIFFICULTY_LEVEL.INTERMEDIATE)}
            >
              Inter&shy;mediate
            </button>
          </li>
          <li>
            <button
              className={classNames(styles.levelCircle, styles.black)}
              onClick={handleLevelSelection(DIFFICULTY_LEVEL.ADVANCED)}
            >
              Advanced
            </button>
          </li>
        </ul>
      </div>
    </SplashScreen>
  );
};
