import React, { FC, memo, SyntheticEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import styles from './bottomPanel.module.css';

interface BottomControlsProps {
  isInSwapMode: boolean;
  handleKeyControlsMouseClicks: (keyName: string) => (e: SyntheticEvent) => void;
}

export const BottomPanelComponent: FC<BottomControlsProps> = ({
  isInSwapMode,
  handleKeyControlsMouseClicks,
}) => {
  return (
    <ul className={styles.bottomPanel} id="bottomPanel">
      {!isInSwapMode && (
        <>
          <li className={styles.control}>
            <a href="#" onClick={handleKeyControlsMouseClicks('ArrowLeft')}>
              <FontAwesomeIcon icon={faCaretLeft} className={styles.icon} />
            </a>
          </li>
          <li className={styles.control}>
            <a href="#" onClick={handleKeyControlsMouseClicks('ArrowDown')}>
              <FontAwesomeIcon icon={faCaretDown} className={styles.icon} />
            </a>
          </li>
          <li className={styles.control}>
            <a href="#" onClick={handleKeyControlsMouseClicks('ArrowRight')}>
              <FontAwesomeIcon icon={faCaretRight} className={styles.icon} />
            </a>
          </li>
        </>
      )}
    </ul>
  );
};

export const BottomPanel = memo(BottomPanelComponent);
