import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isMobile, useMobileOrientation } from 'react-device-detect';

import { PhraseData } from '../../../../types';
import { getPhrase } from '../../../../api';
import { DifficultyContext } from '../../../../context/difficultyContext';
import { getLocalDateWOTime } from '../../../../utils';

//Important: This value should be ~2 times less than `.grid`'s animation length
const gridToLineTransitionDelay = 1000;
const defaultStrikesAmount = 3;
const spaceRatio = 0.9;
let margin = 5;

export function useInitializeGame() {
  const difficultyContext = useContext(DifficultyContext);
  const params = useParams();
  const { isPortrait } = useMobileOrientation();
  const [isLoading, setIsLoading] = useState(true);
  const [phraseError, setPhraseError] = useState<string>();
  const [phraseData, setPhraseData] = useState<PhraseData>();
  const [phraseNoSpaces, setPhraseNoSpaces] = useState<string>();
  const [scrambledNoSpaces, setScrambledNoSpaces] = useState<string>();
  const [isInSwapMode, setIsInSwapMode] = useState(false);
  const [shouldTransitGridToLine, setShouldTransitGridToLine] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [strikesLeft, setStrikesLeft] = useState(defaultStrikesAmount);
  const [sideSize, setSideSize] = useState(60);

  const mainElementRef = useRef<HTMLElement>(null);

  if (isMobile && isPortrait) {
    margin = 2.5;
  }

  const recalculateCellSize = useCallback(
    (respectWidthOnly = false) => {
      if (phraseData && mainElementRef.current) {
        const mainHeight = mainElementRef.current.getBoundingClientRect().height;
        const mainWidth = mainElementRef.current.getBoundingClientRect().width;
        const cellSizeByWidth = Math.floor(
          (mainWidth - (phraseData.phrase.length + 1) * margin) / phraseData.phrase.length,
        );
        const cellSizeByHeight = Math.floor(
          (mainHeight - phraseData.rows * margin) / phraseData.rows,
        );

        if (respectWidthOnly) {
          setSideSize(Math.min(cellSizeByWidth, 80));
          return;
        }

        if (cellSizeByWidth * phraseData.rows + phraseData.rows * margin < mainHeight) {
          setSideSize(cellSizeByWidth);
        }
        if (
          cellSizeByHeight * phraseData.phrase.length + phraseData.phrase.length * margin <
          mainWidth
        ) {
          setSideSize(cellSizeByHeight);
        }
      }
    },
    [phraseData, mainElementRef],
  );

  const fetchPhraseData = useCallback(async () => {
    try {
      if (difficultyContext?.level) {
        const data = (await getPhrase({
          level: difficultyContext.level,
          date: params.date || getLocalDateWOTime(),
          dayOfWeek: difficultyContext.dayOfWeek,
        })) as PhraseData;
        setPhraseData(data);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setPhraseError(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  }, [difficultyContext, params]);

  useEffect(() => {
    const abortController = new AbortController();

    void fetchPhraseData();

    return () => abortController.abort();
  }, [fetchPhraseData]);

  /**
   * Sets initial values once data is fetched
   */
  useEffect(() => {
    if (phraseData) {
      setPhraseNoSpaces(phraseData.phrase.replaceAll(/\s/g, ''));
      setScrambledNoSpaces(phraseData.scrambled.replaceAll(/\s/g, ''));
      setStrikesLeft(phraseData.strike_count);
    }
  }, [phraseData]);

  /**
   * Sets a flag to manage transition from grid to line views
   */
  useEffect(() => {
    if (isInSwapMode) {
      const timeout = window.setTimeout(() => {
        setShouldTransitGridToLine(true);
      }, gridToLineTransitionDelay);

      return () => window.clearTimeout(timeout);
    }
  }, [isInSwapMode]);

  /**
   * Defines cell-size based on phraseData and container's dimensions
   */
  useEffect(() => {
    recalculateCellSize();
  }, []);

  return {
    isLoading,
    phraseData,
    phraseError,
    phraseNoSpaces,
    scrambledNoSpaces,
    isInSwapMode,
    setIsInSwapMode,
    isRevealed,
    setIsRevealed,
    isCorrect,
    setIsCorrect,
    shouldTransitGridToLine,
    strikesLeft,
    setStrikesLeft,
    sideSize,
    mainElementRef,
    recalculateCellSize,
    params,
  };
}
