import { FC, useEffect, useState } from 'react';
import { isMobile, useMobileOrientation } from 'react-device-detect';

import styles from './landscapeLock.module.css';

export const LandscapeLock: FC = () => {
  const orientation = useMobileOrientation();
  const [windowHeight, setWindowHeight] = useState<string | number>('100vh');

  useEffect(() => {
    if (isMobile && orientation.isLandscape) {
      setTimeout(() => {
        setWindowHeight(window.outerHeight);
      }, 100);
    }
  }, [isMobile, orientation.isLandscape]);

  if (isMobile && orientation.isLandscape) {
    return (
      <div className={styles.landscapeLock} style={{ height: windowHeight }}>
        <div className={styles.inner}>
          <div className={styles.logo}>
            <div className={styles.phone} />
          </div>
          <span>
            Sorry, landscape mode is not supported.
            <br />
            Please turn your phone to portrait to proceed.
          </span>
        </div>
      </div>
    );
  }

  return null;
};
