import { FC } from 'react';

import { SplashScreen } from '../../components/splashScreen';

export const AllGood: FC = () => {
  return (
    <SplashScreen>
      <h2>Thank you for playing Analanche!</h2>
    </SplashScreen>
  );
};
