import { useEffect, useState } from 'react';

import { PhraseData } from '../../../../types';

interface useTimersProps {
  phraseData?: PhraseData;
  isCorrect: boolean;
  isRevealed: boolean;
  strikesLeft: number;
}

/**
 * Initializes timers for future statistics and scoring and allows to observe ticks and drop characters as the time goes
 */
export function useTimers({ phraseData, isCorrect, isRevealed, strikesLeft }: useTimersProps) {
  //This timer changes value based on `tickInterval` and serves for game mechanics
  const [timer, setTimer] = useState<number>(0);
  const [pausedTimer, setPausedTimer] = useState<number>(0);
  const [isPaused, setIsPaused] = useState(false);
  const [warmupTimer, setWarmupTimer] = useState(5 * 1000);
  //This timer serves only visual purposes and independent of `tickInterval`
  const [visualTimer, setVisualTimer] = useState(0);

  useEffect(() => {
    if (isPaused) {
      const timeout = window.setTimeout(() => {
        setPausedTimer(pausedTimer + 1000);
      }, 1000);

      return () => window.clearTimeout(timeout);
    }
  }, [isPaused, pausedTimer]);

  useEffect(() => {
    if (!isPaused && !isCorrect && !warmupTimer && !isRevealed && strikesLeft && phraseData) {
      const timeout = window.setTimeout(() => {
        setVisualTimer(visualTimer + 10);
      }, 10);

      return () => window.clearTimeout(timeout);
    }
  }, [visualTimer, isPaused, isCorrect, warmupTimer, strikesLeft, isRevealed, phraseData]);

  useEffect(() => {
    if (
      phraseData?.interval &&
      !isPaused &&
      !isCorrect &&
      !isRevealed &&
      !warmupTimer &&
      strikesLeft
    ) {
      const timeout = window.setTimeout(() => {
        setTimer(timer + phraseData.interval);
      }, phraseData.interval);

      return () => window.clearTimeout(timeout);
    }
  }, [timer, phraseData, isPaused, isCorrect, warmupTimer, strikesLeft]);

  /**
   * Responsible for warm-up countdown we show to people before blocks start falling
   */
  useEffect(() => {
    if (phraseData && warmupTimer && !isPaused) {
      const timeout = window.setTimeout(() => {
        setWarmupTimer(warmupTimer - 1000);
      }, 1000);

      return () => window.clearTimeout(timeout);
    }
  }, [warmupTimer, isPaused, phraseData]);

  useEffect(() => {
    if (phraseData?.countdown) {
      setWarmupTimer(phraseData.countdown * 1000);
    }
  }, [phraseData?.countdown]);

  return {
    timer,
    setTimer,
    pausedTimer,
    isPaused,
    setIsPaused,
    warmupTimer,
    setWarmupTimer,
    visualTimer,
    setVisualTimer,
  };
}
