import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import logoCircle from '../../../logo-circle.png';
import { getGameNumber } from '../../../utils';

import { useGame } from './hooks/useGame';
import { TopControls } from './topControls';
import { MessageBox } from './messageBox';
import { Grid } from './grid';
import { Line } from './line';
import { BottomPanel } from './bottomPanel';
import { WarmupCountdown } from './warmupCountdown';
import { Share } from './share';
import { Error } from './error';
import { Header } from './header';

import styles from './index.module.css';

export const Game: FC = () => {
  const {
    isLoading,
    response,
    phraseError,
    charactersList,
    isPaused,
    togglePlayPause,
    isInSwapMode,
    handleSwapSelection,
    handleDropAndSwap,
    handleKeyControlsMouseClicks,
    initialSwapCharacter,
    isCorrect,
    isRevealed,
    handleRevealClick,
    handlePeekActiveCharacter,
    shouldTransitGridToLine,
    strikesLeft,
    sideSize,
    mainElementRef,
    warmupTimer,
    setWarmupTimer,
    recalculateCellSize,
    visualTimer,
    params,
    isActiveCharacterFlashingTimeout,
  } = useGame();

  const shouldShowLoading = isLoading || (!phraseError && !(charactersList && response));
  // Show `Share` button only if all conditions are met:
  // 1. It's today's game
  // 2. User solved or failed
  // 3. User got here without using `Reveal` button
  const shouldShowShareButton =
    !params.date && !isRevealed && (isCorrect || (!isCorrect && !strikesLeft));

  if (shouldShowLoading) {
    return <div>Loading...</div>;
  }

  if (phraseError) {
    return (
      <div className={styles.game}>
        <header className={styles.header} id="header">
          <div className={styles.headerInner}>
            <Link to="/" className={styles.titleLink}>
              <img
                src={logoCircle}
                alt="Analanche | The Game"
                title="Analanche | The Game"
                className={styles.logo}
              />
              Analanche #{getGameNumber(params.date)}
            </Link>
            <h4>Decode the anagram as the blocks fall</h4>
          </div>
        </header>
        <main id="main" className={styles.innerWrapper}>
          <Error phraseError={phraseError} />
        </main>
      </div>
    );
  }

  return (
    <div className={styles.game}>
      <Header date={params.date} gameNumber={response?.number} />
      <TopControls
        visualTimer={visualTimer}
        isCorrect={isCorrect}
        isRevealed={isRevealed}
        togglePlayPause={togglePlayPause}
        isInSwapMode={isInSwapMode}
        handleRevealClick={handleRevealClick}
        handlePeekActiveCharacter={handlePeekActiveCharacter}
        warmupTimer={warmupTimer}
      />
      <MessageBox
        isInSwapMode={isInSwapMode}
        isCorrect={isCorrect}
        isRevealed={isRevealed}
        strikesLeft={strikesLeft}
        clue={response!.clue}
      />
      <main
        id="main"
        ref={mainElementRef}
        className={classNames(styles.innerWrapper, {
          [styles.inSwapMode]: shouldTransitGridToLine,
        })}
      >
        {warmupTimer || shouldTransitGridToLine ? (
          <Line
            response={response!}
            charactersList={charactersList!}
            handleSwapSelection={handleSwapSelection}
            initialSwapCharacter={initialSwapCharacter}
            isPaused={isPaused}
            isCorrect={isCorrect}
            isRevealed={isRevealed}
            sideSize={sideSize}
            strikesLeft={strikesLeft}
            warmupTimer={warmupTimer}
            recalculateCellSize={recalculateCellSize}
          />
        ) : (
          <Grid
            response={response!}
            charactersList={charactersList!}
            handleDropAndSwap={handleDropAndSwap}
            isInSwapMode={isInSwapMode}
            isPaused={isPaused}
            sideSize={sideSize}
            recalculateCellSize={recalculateCellSize}
            isActiveCharacterFlashingTimeout={isActiveCharacterFlashingTimeout}
          />
        )}
        {!!warmupTimer && (
          <WarmupCountdown warmupTimer={warmupTimer} setWarmupTimer={setWarmupTimer} />
        )}
        {shouldShowShareButton && (
          <Share
            response={response!}
            charactersList={charactersList!}
            visualTimer={visualTimer}
            isCorrect={isCorrect}
          />
        )}
        {(isCorrect || (!isCorrect && !strikesLeft)) && (
          <Link to="/history" className={styles.anotherOne}>
            Try Another One
          </Link>
        )}
      </main>
      <footer
        className={classNames(styles.footer, { [styles.inSwapMode]: shouldTransitGridToLine })}
        id="footer"
      >
        <BottomPanel
          isInSwapMode={isInSwapMode}
          handleKeyControlsMouseClicks={handleKeyControlsMouseClicks}
        />
      </footer>
    </div>
  );
};
