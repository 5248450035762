import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'react-calendar';
import ReactGA from 'react-ga4';

import 'react-calendar/dist/Calendar.css';

import { getLocalDateWOTime } from '../../utils';
import { SplashScreen } from '../../components/splashScreen';

import styles from './index.module.css';

export const History = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview' });
  }, []);

  return (
    <SplashScreen>
      <h2>Select past puzzles</h2>
      <Calendar
        className={styles.calendar}
        minDate={new Date(2024, 0, 1)}
        maxDate={new Date()}
        tileDisabled={({ activeStartDate, date }) => {
          const isPlayed = localStorage.getItem(getLocalDateWOTime(date));
          return !!isPlayed;
        }}
        onClickDay={(date) => {
          navigate(`/play/${getLocalDateWOTime(date)}`);
        }}
      />
    </SplashScreen>
  );
};
