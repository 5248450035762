import { SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DifficultyContext } from '../../../../context/difficultyContext';
import { DIFFICULTY_LEVEL } from '../../../../constants';

import styles from './index.module.css';

interface UseTopControlsProps {
  togglePlayPause: (e: SyntheticEvent) => void;
  isInSwapMode: boolean;
}

export function useTopControls({ togglePlayPause, isInSwapMode }: UseTopControlsProps) {
  const difficultyContext = useContext(DifficultyContext);
  const navigate = useNavigate();
  const params = useParams();
  const [isManualShown, setIsManualShown] = useState(false);
  const [isFeedbackShown, setIsFeedbackShown] = useState(false);

  const showManual = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsManualShown(true);
    togglePlayPause(e);
  };
  const hideManual = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsManualShown(false);
    togglePlayPause(e);
  };
  const showFeedback = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsFeedbackShown(true);
    togglePlayPause(e);
  };
  const hideFeedback = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsFeedbackShown(false);
    togglePlayPause(e);
  };
  const decreaseLevel = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();

      if (
        !difficultyContext?.level ||
        difficultyContext.level === DIFFICULTY_LEVEL.BEGINNER ||
        isInSwapMode
      ) {
        return;
      }

      if (window.confirm('Are you sure you want to restart one level down?')) {
        difficultyContext?.setLevel(difficultyContext.level - 1);
      }
    },
    [difficultyContext, params, navigate, isInSwapMode],
  );

  const levelStyles = {
    ...(difficultyContext &&
      difficultyContext.level && {
        [styles[`level-${difficultyContext.level}`]]:
          difficultyContext && !!difficultyContext.level,
      }),
  };

  return {
    isManualShown,
    showManual,
    hideManual,
    isFeedbackShown,
    showFeedback,
    hideFeedback,
    decreaseLevel,
    levelStyles,
  };
}
