import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactGA from 'react-ga4';

import reportWebVitals from './reportWebVitals';
import { DifficultyProvider } from './context/difficultyContext';
import { Main } from './pages/main';
import { Play } from './pages/play';
import { History } from './pages/history';
import { ErrorPage } from './pages/error';
import { LandscapeLock } from './components/landscapeLock';
import { AllGood } from './pages/all-good';

import './index.css';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/play/:date?',
    element: <Play />,
  },
  {
    path: '/history',
    element: <History />,
  },
  {
    path: '/all-good',
    element: <AllGood />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <DifficultyProvider>
      <RouterProvider router={router} />
      <LandscapeLock />
    </DifficultyProvider>
  </React.StrictMode>,
);

ReactGA.initialize('G-Z347W24EP8');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
