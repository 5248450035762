import { FC } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faCheck } from '@fortawesome/free-solid-svg-icons';

import { ShareProps, useShare } from './useShare';

import styles from './index.module.css';

export const Share: FC<ShareProps> = ({ response, charactersList, visualTimer, isCorrect }) => {
  const { isCopied, handleShare } = useShare({ response, charactersList, visualTimer, isCorrect });

  return (
    <button className={classNames('button', styles.shareButton)} onClick={handleShare}>
      Share?{' '}
      <FontAwesomeIcon
        icon={isCopied ? faCheck : faShare}
        className={classNames(styles.icon, { [styles.check]: isCopied })}
      />
    </button>
  );
};
