import React, { FC, memo, useContext } from 'react';

import { DIFFICULTY_LEVEL } from '../../../constants';
import { DifficultyContext } from '../../../context/difficultyContext';

import styles from './messageBox.module.css';

interface MessageBoxProps {
  isInSwapMode: boolean;
  isCorrect: boolean;
  isRevealed: boolean;
  clue?: string;
  strikesLeft: number;
}

export const MessageBoxComponent: FC<MessageBoxProps> = ({
  isInSwapMode,
  isCorrect,
  isRevealed,
  clue,
  strikesLeft,
}) => {
  const difficultyContext = useContext(DifficultyContext);
  const clueMessage = clue && (
    <p className={styles.clue}>
      <b>Hint:</b>&nbsp;{clue}
    </p>
  );
  let mainMessage;

  if (isInSwapMode) {
    mainMessage = (
      <p>Click or tap on any two letters to swap ... but, one wrong swap and game over!</p>
    );
  }

  if (isCorrect) {
    mainMessage = (
      <p className={styles.correct}>
        Congratulations! You've successfully solved the Analanche puzzle!
      </p>
    );
    if (difficultyContext?.level === DIFFICULTY_LEVEL.ADVANCED) {
      mainMessage = (
        <p className={styles.correct}>
          Congratulations! You've successfully solved the Analanche Black Level puzzle!
        </p>
      );
    }
  }

  if (isInSwapMode && (isRevealed || (!isCorrect && !strikesLeft))) {
    mainMessage = <p className={styles.wrong}>Better luck next time!</p>;
  }

  return (
    <aside className={styles.messageBox} id="messageBox">
      {clueMessage}
      {mainMessage}
    </aside>
  );
};

export const MessageBox = memo(MessageBoxComponent);
