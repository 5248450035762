import React, { FC } from 'react';
import classNames from 'classnames';

import { useWarmupCountdown } from './useWarmupCountdown';

import styles from './index.module.css';

interface WarmupCountdownProps {
  warmupTimer: number;
  setWarmupTimer: React.Dispatch<React.SetStateAction<number>>;
}

export const WarmupCountdown: FC<WarmupCountdownProps> = ({ warmupTimer, setWarmupTimer }) => {
  const { handleLevelIncrease, handleSkip } = useWarmupCountdown({ setWarmupTimer });

  return (
    <div className={styles.warmupCountdown}>
      <h2>
        <span onClick={handleLevelIncrease} className={styles.name}>
          Analanche
        </span>{' '}
        starting in
      </h2>
      <h1>{warmupTimer / 1000}</h1>
      <button
        onClick={handleSkip}
        className={classNames('button', styles.skip)}
        title="Skip Countdown"
      >
        Skip Countdown
      </button>
    </div>
  );
};
