import { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { DifficultyContext } from '../../context/difficultyContext';
import { DIFFICULTY_LEVEL } from '../../constants';
import { getGameNumber, getLocalDateWOTime } from '../../utils';
import { PhraseData, StoredResult } from '../../types';
import { getPhrase } from '../../api';

export function useMain() {
  const difficultyContext = useContext(DifficultyContext);
  const navigate = useNavigate();
  const isPlayed = localStorage.getItem(getLocalDateWOTime());

  const [isManualShown, setIsManualShown] = useState(false);
  const [gameNumber, setGameNumber] = useState(getGameNumber());

  const showManual = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsManualShown(true);
  };
  const hideManual = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsManualShown(false);
  };

  const handlePlayClick = useCallback(
    (e: SyntheticEvent) => {
      if (isPlayed) {
        e.preventDefault();
        const result = JSON.parse(isPlayed) as StoredResult;
        if (
          window.confirm(
            // eslint-disable-next-line max-len
            `Looks like you have already tried this puzzle.\n\rHint: ${result.clue}\n${result.answer}\n\rTry another one?`,
          )
        ) {
          navigate('/history');
        } else {
          navigate('/all-good');
        }
      }
    },
    [isPlayed],
  );

  const fetchPhraseData = useCallback(async () => {
    if (difficultyContext?.level) {
      const data = (await getPhrase({
        level: difficultyContext?.level,
        date: getLocalDateWOTime(),
      })) as PhraseData;
      setGameNumber(data.number);
    }
  }, [difficultyContext]);

  useEffect(() => {
    difficultyContext?.setLevel(DIFFICULTY_LEVEL.INTERMEDIATE);
    void fetchPhraseData();
  }, [difficultyContext]);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview' });
  }, []);

  return {
    isManualShown,
    showManual,
    hideManual,
    handlePlayClick,
    gameNumber,
  };
}
