import { FC, memo, useEffect } from 'react';
import classNames from 'classnames';

import { Character, PhraseData } from '../../../types';

import { LineCell } from './cell';

import styles from './line.module.css';

interface LineProps {
  response: PhraseData;
  charactersList: Character[];
  handleSwapSelection: (character: Character) => void;
  initialSwapCharacter?: Character;
  isPaused: boolean;
  isCorrect: boolean;
  isRevealed: boolean;
  sideSize: number;
  strikesLeft: number;
  warmupTimer: number;
  recalculateCellSize: (respectWidthOnly?: boolean) => void;
}

export const LineComponent: FC<LineProps> = ({
  response,
  charactersList,
  handleSwapSelection,
  initialSwapCharacter,
  isPaused,
  isCorrect,
  isRevealed,
  sideSize,
  strikesLeft,
  warmupTimer,
  recalculateCellSize,
}) => {
  const cellsList = [];
  let spacesPlaced = 0;

  useEffect(() => {
    recalculateCellSize(true);
  }, [recalculateCellSize]);

  for (let i = 0; i < response.columns; i++) {
    const matchingCharacter = charactersList.find((character) => character.currentPosition.x === i);

    if (!matchingCharacter) {
      continue;
    }

    cellsList.push(
      <LineCell
        key={i}
        character={matchingCharacter}
        initialSwapCharacter={initialSwapCharacter}
        sideSize={sideSize}
        handleSwapSelection={handleSwapSelection}
      />,
    );

    if (response.scrambled[i + 1 + spacesPlaced] === ' ') {
      const extraProps = {
        style: {
          width: sideSize * 0.9,
          flexBasis: sideSize * 0.9,
        },
      };
      spacesPlaced++;
      cellsList.push(<div className={styles.empty} {...extraProps} key={`empty-${i}`} />);
    }
  }
  return (
    <div
      className={classNames(styles.line, {
        [styles.disabled]: warmupTimer || isPaused || isCorrect || isRevealed || !strikesLeft,
      })}
    >
      {cellsList}
    </div>
  );
};

export const Line = memo(LineComponent);
