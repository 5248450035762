import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { SplashScreen } from '../../components/splashScreen';
import { HowItWorks } from '../../components/howItWorks';

import { useMain } from './useMain';

import styles from './index.module.css';

export const Main: FC = () => {
  const { isManualShown, showManual, hideManual, handlePlayClick, gameNumber } = useMain();

  return (
    <SplashScreen>
      <h2>Decode the anagram as the blocks fall</h2>
      <ul className={styles.actionsList}>
        <li>
          <Link to="/play" className="button active" onClick={handlePlayClick}>
            Play Today's
          </Link>
        </li>
        <li>
          <Link to="/history" className="button">
            Past puzzles
          </Link>
        </li>
        <li>
          <a href="#" className="button" onClick={showManual}>
            How to play
          </a>
        </li>
        <li>
          <b>
            {new Date().toLocaleDateString('en-US', {
              month: 'long',
              day: '2-digit',
              year: 'numeric',
            })}
          </b>
          <br />
          <span>Analanche #{gameNumber}</span>
        </li>
      </ul>
      {isManualShown && <HowItWorks hideManual={hideManual} />}
    </SplashScreen>
  );
};
