import React, { createContext, FC, PropsWithChildren, useState } from 'react';

import { DAY_OF_WEEK, DIFFICULTY_LEVEL } from '../constants';

interface DifficultyContextProps {
  level?: DIFFICULTY_LEVEL;
  setLevel: React.Dispatch<React.SetStateAction<DIFFICULTY_LEVEL>>;
  dayOfWeek?: DAY_OF_WEEK;
  setDayOfWeek: React.Dispatch<React.SetStateAction<DAY_OF_WEEK | undefined>>;
}

export const DifficultyContext = createContext<DifficultyContextProps | null>(null);

export const DifficultyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [level, setLevel] = useState<DIFFICULTY_LEVEL>(DIFFICULTY_LEVEL.INTERMEDIATE);
  const [dayOfWeek, setDayOfWeek] = useState<DAY_OF_WEEK>();

  return (
    <DifficultyContext.Provider value={{ level, setLevel, dayOfWeek, setDayOfWeek }}>
      {children}
    </DifficultyContext.Provider>
  );
};
