import queryString from 'query-string';

import { DAY_OF_WEEK, DIFFICULTY_LEVEL, SERVICE_MAIL } from './constants';

interface GetPhraseParams {
  level: DIFFICULTY_LEVEL;
  dayOfWeek?: DAY_OF_WEEK;
  date?: string;
}

export const getPhrase = async (params: GetPhraseParams) => {
  const url = `https://api.analanche.com:443/phrase?${queryString.stringify(params)}`;
  const response = await fetch(url, {
    mode: 'cors',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error(
      `Hmmm... seems like something isn't right.<br /> Please try reloading the page or mail us at 
<a href="mailto:${SERVICE_MAIL}&subject=Game loading error">${SERVICE_MAIL}</a> 
if the error persists`,
    );
  }

  return response.json();
};

interface FeedbackFormData {
  email: string;
  feedback: string;
}

export const sendFeedback = async (feedback: FeedbackFormData) => {
  const url = 'https://api.analanche.com:443/feedback';

  const response = await fetch(url, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(feedback),
  });

  if (!response.ok) {
    throw new Error(
      `Hmmm... seems like something isn't right.<br /> Please try reloading the page or mail us at 
<a href="mailto:${SERVICE_MAIL}&subject=Game loading error">${SERVICE_MAIL}</a> 
if the error persists`,
    );
  }

  return response.json();
};
