import React, { FC } from 'react';
import classNames from 'classnames';

import { Character } from '../../../../types';

import { Cell } from './cell';

import styles from './index.module.css';

interface LineCellProps {
  character: Character;
  initialSwapCharacter?: Character;
  sideSize: number;
  handleSwapSelection: (character: Character) => void;
}

export const LineCell: FC<LineCellProps> = ({
  character,
  sideSize,
  handleSwapSelection,
  initialSwapCharacter,
}) => {
  const cellClasses = classNames(styles.withCharacter, styles.swappable, {
    [styles.initialSwap]:
      initialSwapCharacter &&
      initialSwapCharacter?.currentPosition.x === character?.currentPosition.x &&
      initialSwapCharacter?.currentPosition.y === character?.currentPosition.y,
    [styles.isInCorrectPosition]:
      character?.currentPosition.y === character?.correctPosition.y &&
      character?.correctPosition.x.includes(character?.currentPosition.x),
  });

  const clickHandler = () => {
    handleSwapSelection(character);
  };

  return (
    <Cell
      character={character}
      sideSize={sideSize}
      clickHandler={clickHandler}
      cellClasses={cellClasses}
    />
  );
};
