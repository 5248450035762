import { FC } from 'react';
import { useRouteError } from 'react-router-dom';

import styles from './index.module.css';

export const ErrorPage: FC = () => {
  const error = useRouteError();

  return (
    <div className={styles.errorPage}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>{(error as Error)?.message || (error as { statusText?: string })?.statusText}</p>
    </div>
  );
};
