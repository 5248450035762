import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';

import {
  dropAllCharactersInstantly,
  moveActiveCharacterDown,
  moveActiveCharacterIntoPosition,
  moveActiveCharacterLeft,
  moveActiveCharacterRight,
  setNextActiveCharacter,
  swapCharactersPositions,
} from '../../../../utils';
import { Character } from '../../../../types';
import { DIFFICULTY_LEVEL } from '../../../../constants';
import { DifficultyContext } from '../../../../context/difficultyContext';

const allowedKeys = ['ArrowRight', 'ArrowLeft', 'ArrowDown'];

interface useControlsProps {
  charactersList?: Character[];
  setCharactersList: React.Dispatch<React.SetStateAction<Character[] | undefined>>;
  isPaused: boolean;
  setIsPaused: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRevealed: React.Dispatch<React.SetStateAction<boolean>>;
  setIsInSwapMode: React.Dispatch<React.SetStateAction<boolean>>;
  phraseNoSpaces?: string;
  setStrikesLeft: React.Dispatch<React.SetStateAction<number>>;
  setVisualTimer: React.Dispatch<React.SetStateAction<number>>;
  warmupTimer?: number;
}

export function useControls({
  charactersList,
  setCharactersList,
  isPaused,
  setIsPaused,
  setIsRevealed,
  setIsInSwapMode,
  phraseNoSpaces,
  setStrikesLeft,
  setVisualTimer,
  warmupTimer,
}: useControlsProps) {
  const difficultyContext = useContext(DifficultyContext);
  const [initialSwapCharacter, setInitialSwapCharacter] = useState<Character>();
  const [peekedCharacterIndex, setPeekedCharacterIndex] = useState<number>();
  //Should flash active character and correct positions if they're already occupied with other characters
  const [isActiveCharacterFlashingTimeout, setIsActiveCharacterFlashingTimeout] =
    useState<number>();

  const moveActiveCharacter = useCallback(
    (keyName: string) => {
      //Skip the entire logic if no keyName present or if keyName is not a part of allowedKeys
      if (!keyName || !allowedKeys.includes(keyName)) {
        return;
      }

      if (!charactersList) {
        return;
      }

      if (warmupTimer) {
        return;
      }

      if (isPaused) {
        setIsPaused(false);
      }

      if (keyName === 'ArrowRight') {
        setCharactersList(moveActiveCharacterRight(charactersList));
      }
      if (keyName === 'ArrowLeft') {
        setCharactersList(moveActiveCharacterLeft(charactersList));
      }
      if (keyName === 'ArrowDown') {
        setCharactersList(moveActiveCharacterDown(charactersList));
      }
    },
    [charactersList, setCharactersList, isPaused, setIsPaused],
  );

  const handleKeypress = useCallback(
    (e: KeyboardEvent) => {
      moveActiveCharacter(e.key);
    },
    [moveActiveCharacter],
  );

  const handleKeyControlsMouseClicks = useCallback(
    (keyName: string) => {
      return (e: SyntheticEvent) => {
        e.preventDefault();
        moveActiveCharacter(keyName);
      };
    },
    [moveActiveCharacter],
  );

  const togglePlayPause = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (isPaused) {
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    },
    [isPaused, setIsPaused],
  );

  const handleSolve = useCallback(() => {
    if (!charactersList) {
      return;
    }

    setIsPaused(false);
    setIsInSwapMode(true);
    setCharactersList(dropAllCharactersInstantly(charactersList));
  }, [charactersList, setCharactersList, setIsInSwapMode, setIsPaused]);

  const handleRevealClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      if (
        window.confirm('Are you sure you want to reveal the answer and end the game?') &&
        phraseNoSpaces
      ) {
        setCharactersList(
          phraseNoSpaces.split('').reduce((acc, character, key) => {
            return [
              ...acc,
              {
                character,
                isActive: false,
                isPlaced: true,
                currentPosition: {
                  x: key,
                  y: phraseNoSpaces.length - 1,
                },
                correctPosition: {
                  x: [key],
                  y: phraseNoSpaces.length - 1,
                },
              },
            ];
          }, [] as Character[]),
        );
        setIsInSwapMode(true);
        setIsRevealed(true);
      }
    },
    [setIsRevealed, phraseNoSpaces],
  );

  const handlePeekActiveCharacter = useCallback(() => {
    if (!charactersList) {
      return;
    }

    const activeCharacterIndex = charactersList.findIndex((character) => character.isActive);
    const soughtCharacter = charactersList.find(
      (character) =>
        !character.isPlaced &&
        charactersList[activeCharacterIndex]?.correctPosition.x.includes(
          character.currentPosition.x,
        ),
    );

    //Handles the case when all correct positions for the active character have been occupied
    if (activeCharacterIndex !== -1 && !soughtCharacter) {
      if (!isActiveCharacterFlashingTimeout) {
        const timeout = window.setTimeout(() => {
          clearTimeout(isActiveCharacterFlashingTimeout);
          setIsActiveCharacterFlashingTimeout(undefined);
        }, 1000);
        setIsActiveCharacterFlashingTimeout(timeout);
      }
      //If this is the first time we're trying to peek this character - add 10 seconds penalty and
      // flash active character and occupied positions in red
      if (activeCharacterIndex !== peekedCharacterIndex) {
        setPeekedCharacterIndex(activeCharacterIndex);
        setVisualTimer((prevState) => {
          return prevState + 10 * 1000;
        });
        return;
      }
    }

    if (activeCharacterIndex === -1 || !soughtCharacter) {
      return;
    }

    setCharactersList(
      setNextActiveCharacter(
        moveActiveCharacterIntoPosition({
          charactersList,
          positionX: soughtCharacter.currentPosition.x,
          positionY: charactersList[activeCharacterIndex].correctPosition.y,
        }),
        false,
      ),
    );

    setVisualTimer((prevState) => {
      return prevState + 10 * 1000;
    });
  }, [
    charactersList,
    setCharactersList,
    setVisualTimer,
    peekedCharacterIndex,
    setPeekedCharacterIndex,
    isActiveCharacterFlashingTimeout,
    setIsActiveCharacterFlashingTimeout,
  ]);

  const handleSwapSelection = useCallback(
    (nextSwapCharacter: Character) => {
      if (
        !charactersList ||
        !nextSwapCharacter.isPlaced ||
        nextSwapCharacter.correctPosition.x.includes(nextSwapCharacter.currentPosition.x)
      ) {
        return;
      }

      if (initialSwapCharacter?.currentPosition.x === nextSwapCharacter.currentPosition.x) {
        return setInitialSwapCharacter(undefined);
      }

      if (!initialSwapCharacter) {
        return setInitialSwapCharacter(nextSwapCharacter);
      }

      const indexA = charactersList.findIndex(
        (character) => character.currentPosition.x === initialSwapCharacter.currentPosition.x,
      );
      const indexB = charactersList.findIndex(
        (character) => character.currentPosition.x === nextSwapCharacter.currentPosition.x,
      );

      // Here we predict if at least a one of two characters will be placed in a correct position and if not - use one strike
      if (
        !charactersList[indexA].correctPosition.x.includes(
          charactersList[indexB].currentPosition.x,
        ) &&
        !charactersList[indexB].correctPosition.x.includes(charactersList[indexA].currentPosition.x)
      ) {
        setStrikesLeft((oldValue) => --oldValue);
      }

      setCharactersList(swapCharactersPositions({ charactersList, indexA, indexB }));
      setInitialSwapCharacter(undefined);
    },
    [charactersList, setCharactersList, initialSwapCharacter, setStrikesLeft],
  );

  const handleDropAndSwap = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (!charactersList || difficultyContext?.level !== DIFFICULTY_LEVEL.ADVANCED) {
        return;
      }
      setCharactersList(
        setNextActiveCharacter(
          moveActiveCharacterIntoPosition({ charactersList, positionX: x, positionY: y }),
          false,
        ),
      );
    },
    [charactersList, setCharactersList, difficultyContext?.level],
  );

  useEffect(() => {
    window.document.addEventListener('keyup', handleKeypress);
    return () => window.document.removeEventListener('keyup', handleKeypress);
  }, [handleKeypress]);

  return {
    initialSwapCharacter,
    handleSolve,
    handleKeyControlsMouseClicks,
    handleRevealClick,
    handlePeekActiveCharacter,
    handleSwapSelection,
    handleDropAndSwap,
    togglePlayPause,
    isActiveCharacterFlashingTimeout,
  };
}
