import { ChangeEvent, SyntheticEvent, useState } from 'react';

import { sendFeedback } from '../../api';

export function useFeedback() {
  const [isSent, setIsSent] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');

  const handleFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await sendFeedback({ email, feedback: body });
    } finally {
      setIsSent(true);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const handleBodyChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.currentTarget.value);
  };

  return {
    isSent,
    email,
    handleEmailChange,
    body,
    handleBodyChange,
    handleFormSubmit,
  };
}
