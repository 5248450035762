import { useEffect, useState } from 'react';

import { Character, PhraseData } from '../../../../types';
import { setNextActiveCharacter } from '../../../../utils';

interface useDefineCharactersListProps {
  phraseData?: PhraseData;
  phraseNoSpaces?: string;
  scrambledNoSpaces?: string;
}

export function useDefineCharactersList({
  phraseData,
  phraseNoSpaces,
  scrambledNoSpaces,
}: useDefineCharactersListProps) {
  const [charactersList, setCharactersList] = useState<Character[]>();

  /**
   * Defines the main data-structure that is manipulated all over the game
   */
  useEffect(() => {
    if (phraseNoSpaces && scrambledNoSpaces) {
      const initialCharactersList = scrambledNoSpaces.split('').reduce((acc, character, key) => {
        return [
          ...acc,
          {
            character,
            isActive: false,
            isPlaced: false,
            currentPosition: {
              x: key,
              y: 0,
            },
            correctPosition: {
              x: phraseNoSpaces.split('').reduce((oAcc, oCharacter, oKey) => {
                if (oCharacter === character) {
                  return [...oAcc, oKey];
                }
                return oAcc;
              }, [] as number[]),
              y: (phraseData?.rows || scrambledNoSpaces.length) - 1,
            },
          },
        ];
      }, [] as Character[]);

      setCharactersList(setNextActiveCharacter(initialCharactersList, false));
    }
  }, [phraseNoSpaces, scrambledNoSpaces, phraseData?.rows]);

  return {
    charactersList,
    setCharactersList,
  };
}
