import { FC, memo, useEffect } from 'react';
import classNames from 'classnames';

import { PhraseData, Character } from '../../../types';

import { GridCell } from './cell';

import styles from './grid.module.css';

interface GridProps {
  response: PhraseData;
  charactersList: Character[];
  handleDropAndSwap: ({ x, y }: { x: number; y: number }) => void;
  isInSwapMode: boolean;
  isPaused: boolean;
  sideSize: number;
  recalculateCellSize: () => void;
  isActiveCharacterFlashingTimeout?: number;
}

export const GridComponent: FC<GridProps> = ({
  response,
  charactersList,
  handleDropAndSwap,
  isInSwapMode,
  sideSize,
  recalculateCellSize,
  isActiveCharacterFlashingTimeout,
}) => {
  const activeCharacter = charactersList.find((character) => character.isActive);
  const rowsList = [];
  let spacesPlaced = 0;

  useEffect(() => {
    recalculateCellSize();
  }, []);

  for (let i = 0; i < response.columns; i++) {
    const cellsList = [];

    for (let j = 0; j < response.rows; j++) {
      const matchingCharacter = charactersList.find((character, index) => {
        return character.currentPosition.x === i && character.currentPosition.y === j;
      });
      const isMatchingCharacterTakesWrongPlace =
        matchingCharacter?.isPlaced &&
        matchingCharacter?.character !== activeCharacter?.character &&
        activeCharacter?.correctPosition.x.includes(i);

      cellsList.push(
        <GridCell
          key={`${i}-${j}`}
          response={response}
          character={matchingCharacter}
          sideSize={sideSize}
          cellPosition={{ x: i, y: j }}
          handleDropAndSwap={handleDropAndSwap}
          isActiveCharacterFlashingTimeout={isActiveCharacterFlashingTimeout}
          isMatchingCharacterTakesWrongPlace={isMatchingCharacterTakesWrongPlace}
        />,
      );
    }
    rowsList.push(
      <div className={styles.column} key={i}>
        {' '}
        {cellsList}{' '}
      </div>,
    );

    if (response.scrambled[i + 1 + spacesPlaced] === ' ') {
      const extraProps = {
        style: {
          width: sideSize * 0.9,
          flexBasis: sideSize * 0.9,
        },
      };
      spacesPlaced++;
      rowsList.push(
        <div
          className={classNames(styles.column, styles.empty)}
          {...extraProps}
          key={`empty-${i}`}
        />,
      );
    }
  }

  return (
    <div className={classNames(styles.grid, { [styles.isSolved]: isInSwapMode })}>{rowsList}</div>
  );
};

export const Grid = memo(GridComponent);
