import { isEqual } from 'lodash';

import { Character, PhraseData } from './types';
import { CORRECT_SQUARE, WRONG_SQUARE } from './constants';
import {
  findLastAvailableIndex,
  findFirstAvailableIndex,
  findNextClosestAvailableIndex,
  findPrevClosestAvailableIndex,
} from './helpers';

export function setNextActiveCharacter(charactersList: Character[], shouldRespectCorrect: boolean) {
  let nextActiveCharacterIndex = shouldRespectCorrect
    ? charactersList.findIndex(
        (character) =>
          !character.isActive &&
          !character.isPlaced &&
          character.correctPosition.x.includes(character.currentPosition.x),
      )
    : -1;
  if (nextActiveCharacterIndex === -1) {
    const allAvailableCharacters = charactersList.filter(
      (character) => !character.isActive && !character.isPlaced,
    );

    const nextCharacter =
      allAvailableCharacters[Math.floor(Math.random() * allAvailableCharacters.length)];

    nextActiveCharacterIndex = charactersList.findIndex((character) =>
      isEqual(character, nextCharacter),
    );
  }
  if (nextActiveCharacterIndex === -1) {
    nextActiveCharacterIndex = 0;
  }
  return charactersList.map((character, index) => {
    if (index === nextActiveCharacterIndex) {
      return {
        ...character,
        isActive: true,
      };
    }
    return {
      ...character,
      isActive: false,
    };
  });
}

export function dropAllCharactersInstantly(charactersList: Character[]) {
  return charactersList.map((character) => {
    return {
      ...character,
      isPlaced: true,
      isActive: false,
      currentPosition: {
        ...character.currentPosition,
        y: character.correctPosition.y,
      },
    };
  });
}

export function moveActiveCharacterDown(charactersList: Character[]) {
  return charactersList.map((character, index) => {
    if (character.isActive && !character.isPlaced) {
      return {
        ...character,
        isPlaced: character.currentPosition.y + 1 === character.correctPosition.y,
        currentPosition: {
          ...character.currentPosition,
          y: character.currentPosition.y + 1,
        },
      };
    }
    return { ...character };
  });
}

export function moveActiveCharacterRight(charactersList: Character[]) {
  const activeCharacterIndex = charactersList.findIndex((character) => character.isActive);
  const activeCharacter = charactersList[activeCharacterIndex];

  if (charactersList[activeCharacterIndex].isPlaced) {
    return [...charactersList];
  }

  const lastAvailableElement = charactersList[findLastAvailableIndex(charactersList)];

  const isRightWallReached =
    activeCharacter.currentPosition.x > lastAvailableElement.currentPosition.x;

  if (isRightWallReached) {
    const firstAvailableIndex = findFirstAvailableIndex(charactersList);

    return swapCharactersPositions({
      charactersList,
      indexA: activeCharacterIndex,
      indexB: firstAvailableIndex,
    });
  }

  const nextClosestAvailableIndex = findNextClosestAvailableIndex(charactersList);

  return swapCharactersPositions({
    charactersList,
    indexA: activeCharacterIndex,
    indexB: nextClosestAvailableIndex,
  });
}
export function moveActiveCharacterLeft(charactersList: Character[]) {
  const activeCharacterIndex = charactersList.findIndex((character) => character.isActive);
  const activeCharacter = charactersList[activeCharacterIndex];

  if (charactersList[activeCharacterIndex].isPlaced) {
    return [...charactersList];
  }

  const firstAvailableElement = charactersList[findFirstAvailableIndex(charactersList)];

  const isLeftWallReached =
    activeCharacter.currentPosition.x < firstAvailableElement.currentPosition.x;

  if (isLeftWallReached) {
    const lastAvailableIndex = findLastAvailableIndex(charactersList);

    return swapCharactersPositions({
      charactersList,
      indexA: activeCharacterIndex,
      indexB: lastAvailableIndex,
    });
  }
  const prevClosestAvailableIndex = findPrevClosestAvailableIndex(charactersList);

  return swapCharactersPositions({
    charactersList,
    indexA: activeCharacterIndex,
    indexB: prevClosestAvailableIndex,
  });
}

export function swapCharactersPositions({
  charactersList,
  indexA,
  indexB,
}: {
  charactersList: Character[];
  indexA: number;
  indexB: number;
}) {
  const aPositionX = charactersList[indexA].currentPosition.x;
  const bPositionX = charactersList[indexB].currentPosition.x;

  return charactersList.map((character, index) => {
    if (index === indexA) {
      return {
        ...character,
        currentPosition: {
          ...character.currentPosition,
          x: bPositionX,
        },
      };
    }
    if (index === indexB) {
      return {
        ...character,
        currentPosition: {
          ...character.currentPosition,
          x: aPositionX,
        },
      };
    }
    return { ...character };
  });
}

export function countPlacedCharacters(charactersList: Character[]) {
  return charactersList.filter((character) => character.isPlaced).length;
}

export function countProperlyPlacedCharacters(charactersList: Character[]) {
  return charactersList.filter(
    (character) =>
      character.isPlaced && character.correctPosition.x.includes(character.currentPosition.x),
  ).length;
}

export function moveActiveCharacterIntoPosition({
  charactersList,
  positionX,
  positionY,
}: {
  charactersList: Character[];
  positionX: number;
  positionY: number;
}) {
  const activeCharacterIndex = charactersList.findIndex((character) => character.isActive);
  const soughtCharacterIndex = charactersList.findIndex(
    (character) => character.currentPosition.x === positionX,
  );
  const aPositionX = charactersList[activeCharacterIndex].currentPosition.x;

  //Active character is at the bottom position
  if (
    charactersList[activeCharacterIndex].currentPosition.y ===
    charactersList[activeCharacterIndex].correctPosition.y
  ) {
    return [...charactersList];
  }

  if (charactersList[soughtCharacterIndex].isPlaced) {
    return [...charactersList];
  }

  return charactersList.map((character, index) => {
    if (index === activeCharacterIndex) {
      return {
        ...character,
        isPlaced: true,
        isActive: false,
        currentPosition: {
          x: positionX,
          y: positionY,
        },
      };
    }
    if (index === soughtCharacterIndex) {
      return {
        ...character,
        currentPosition: {
          x: aPositionX,
          y: 0,
        },
      };
    }
    return { ...character };
  });
}

/**
 * All date comparisons are in UTC
 * @param date
 */
export const getGameNumber = (date?: string) => {
  const dateFrom = date || new Date().toISOString().split('T')[0];
  return (
    Math.floor((Date.parse(dateFrom) - Date.parse('Dec 31, 2023')) / (1000 * 60 * 60 * 24)) + 1
  );
};

/**
 * Incoming `date` param should always be a LOCAL date
 * @param date
 */
export const getLocalDateWOTime = (date?: Date) => {
  const gameDate = date || new Date();
  // eslint-disable-next-line max-len
  return `${gameDate.getFullYear()}-${('0' + (gameDate.getMonth() + 1).toString()).slice(-2)}-${('0' + gameDate.getDate().toString()).slice(-2)}`;
};

export const getFormattedSharableAnswer = (response: PhraseData, charactersList: Character[]) => {
  let spacesPlaced = 0;
  let message = '';

  for (let i = 0; i < charactersList.length; i++) {
    const soughtCharacter = charactersList.find((character) => character.currentPosition.x === i);

    if (soughtCharacter!.correctPosition.x.includes(soughtCharacter!.currentPosition.x)) {
      message += CORRECT_SQUARE;
    } else {
      message += WRONG_SQUARE;
    }

    if (response.scrambled[i + 1 + spacesPlaced] === ' ') {
      message += '  ';
      spacesPlaced++;
    }
  }

  return message;
};
