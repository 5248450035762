import { FC, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { DifficultyContext } from '../../context/difficultyContext';
import { getLocalDateWOTime } from '../../utils';

import { Presets } from './presets';
import { Game } from './game';

export const Play: FC = () => {
  const params = useParams();
  const gameDate = params.date || getLocalDateWOTime();
  const context = useContext(DifficultyContext);
  const isPlayed = localStorage.getItem(gameDate);

  if (!!isPlayed) {
    return <Navigate to="/" replace={true} />;
  }

  if (!context?.level) {
    return <Presets />;
  }

  return <Game />;
};
